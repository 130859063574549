import MainPageComponent from "../../components/MainPageComponent";



function MainPage() {
  return (
    <MainPageComponent/>
  );
}

export default MainPage;
